import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import {TextField as MuiTextField} from "@material-ui/core";

/**
 * `TextField` extension
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TextField extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Value
			 * 
			 * @type {String|null}
			 */
			value: props.value

		};

		/**
		 * Input ref
		 */
		this.inputRef = React.createRef();

		/**
		 * Method binds
		 */
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKey = this.handleKey.bind(this);
		this.updateValue = this.updateValue.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();

		this.updateValue(() => setTimeout(() => {
			const v = this.ref?.current?.value;
			if (v && (v !== (this.state.value || ""))) {
				this.handleChangeReal(v, true);
			}
		}, 250));
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if ((this.props.value !== this.state.value)) {
			if ((this.state.value === prevState.value) || this.props.controlled) {
				this.updateValue();
			}
		}
	}


	/**
	 * Blurred.
	 * 
	 * @return {void}
	 */
	handleBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
		else if (!this.props.controlled && (this.props.value !== this.state.value)) {
			this.reportChange(this.state.value);
		}
	}


	/**
	 * Value changed.
	 *
	 * `!e.nativeEvent.data` detects browser autofill (working Chrome 93).
	 * 
	 * @param {Event} e
	 * @param {Boolean} forceReporting optional (`false`)
	 * @return {void}
	 */
	handleChange(e, forceReporting=false) {
		this.handleChangeReal(
			(e.target.value || null),
			(forceReporting || (e && !e?.nativeEvent?.data))
		);
	}


	/**
	 * Value changed.
	 *
	 * Handle the actual change.
	 *
	 * @param {String} value
	 * @param {Boolean} forceReporting optional (`false`)
	 * @return {void}
	 */
	handleChangeReal(value, forceReporting=false) {

		if (this.props.pattern && ((value !== null) || this.props.applyPatternToNullValues)) {
			if (!(new RegExp(this.props.pattern, "g")).test(value)) {
				return;
			}
		}

		this.setState({value});

		if (this.props.controlled || forceReporting) {
			this.reportChange(value);
		}

	}


	/**
	 * Key pressed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKey(e) {
		if ((e.which === 13) && this.props.onEnter) {
			this.props.onEnter();
		}
		else if ((this.props.type === "number") && [69, 169, 61, 173, 187, 189].includes(e.which)) {
			e.preventDefault();
		}
	}


	/**
	 * Report a value change.
	 *
	 * @param {String} value optional Value to report (defaults to state)
	 * @return {void}
	 */
	reportChange(value=undefined) {
		if (!this.props.controlled) value = ((value !== undefined) ? value : this.state.value);
		if (this.props.onChange) this.props.onChange(value, (this.props.valueId || this.props.name));
	}


	/**
	 * Update our value.
	 *
	 * @param {Function} cb optional Callback
	 * @return {void}
	 */
	updateValue(cb=null) {
		this.setState({value: (this.props.value || null)}, cb);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiTextField
				autoComplete={this.props.autoComplete}
				autoFocus={this.props.autoFocus}
				color={(this.props.color || "secondary")}
				disabled={this.props.disabled}
				error={this.props.error}
				fullWidth={this.props.fullWidth}
				helperText={this.props.helperText}
				inputRef={this.ref}
				inputProps={this.inputProps}
				InputProps={this.props.InputProps}
				InputLabelProps={this.InputLabelProps}
				label={this.props.label}
				multiline={this.props.multiline}
				name={this.props.name}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				onFocus={this.props.onFocus}
				onKeyDown={this.handleKey}
				placeholder={(this.props.placeholder || (this.props.placeholderLabel ? this.props.label : undefined))}
				required={this.props.required}
				rows={this.props.rows}
				size={this.props.size}
				type={this.props.type}
				value={(this.value?.toString() || "")}
				variant={this.props.variant} />
		);
	}


	/**
	 * `inputProps`
	 * 
	 * @return {Object}
	 */
	get inputProps() {
		return {
			disabled: (this.props.disabled || this.props.readOnly),
			className: this.props.inputClassname,
			max: this.props.max,
			min: this.props.min,
			maxLength: (this.props.varchar ? 250 : this.props.maxLength),
			minLength: this.props.minLength,
			pattern: this.props.pattern,
			readOnly: this.props.readOnly,
			...this.props.inputProps
		};
	}


	/**
	 * Get our input ref.
	 *
	 * @return {ReactRef}
	 */
	get ref() {
		return (this.props.inputRef || this.inputRef);
	}


	/**
	 * Get our value.
	 * 
	 * @return {String}
	 */
	get value() {
		if (this.props.controlled) {
			return this.props.value;
		}
		else return this.state.value;
	}


	/**
	 * `InputLabelProps`
	 * 
	 * @return {Object}
	 */
	get InputLabelProps() {
		return {
			shrink: ((((this.props.placeholder || this.props.placeholderLabel) && !this.props.noAutoLabelShrink) || this.props.labelShrink) ? true : undefined)
		};
	}

}

export default TextField;
