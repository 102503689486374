import React from "react";
import Banner from "Components/Banner.js";
import BasketCard from "Checkout/BasketCard.js";
import BasketExpiryTimer from "Checkout/BasketExpiryTimer.js";
import Button from "Components/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import DialogInterstitial from "Components/DialogInterstitial.js";
import Fade from "@material-ui/core/Fade";
import Flex from "Components/Flex.js";
import IconButton from "@material-ui/core/IconButton";
import Navigator from "App/Navigator.js";
import Routes from "Resources/Routes.json";
import String from "Components/String.js";
import dCheckoutBasketAdditionData from "Dispatchers/dCheckoutBasketAdditionData.js";
import scss from "./AddedToBasketOverlay.module.scss";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import {connect} from "react-redux";
import {CheckoutBasketItem as BasketItem, ItemClaimErrorCodes} from "@hps/hops-sdk-js";

/**
 * Overlay that appears after adding an item to the checkout basket
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AddedToBasketOverlay extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DialogInterstitial
				hideCloseButton={true}
				onClose={this.constructor.handleClose}
				open={((this.props.checkoutBasketAdditionData !== null) && !this.props.forceClosed)}
				TransitionComponent={Fade}>
				<Flex justifyContent="space-between">
					<Flex>
						<Flex className={scss.header} mb={-1} mt={-1}>
							<IconButton
								color="primary"
								onClick={this.constructor.handleClose}
								size="small">
								<CloseIcon />
							</IconButton>
							<String
								centre={true}
								str="Your Basket"
								variant="h5" />
							{(this.props.checkoutBasketExpiry && <BasketExpiryTimer expiry={this.props.checkoutBasketExpiry} />)}
						</Flex>
						{(!!this.props.checkoutBasketAdditionData?.Errors?.length && this.renderErrors())}
						<BasketCard
							basket={this.props.checkoutBasket}
							basketDiscounts={this.props.checkoutBasketDiscounts}
							basketLoading={this.props.checkoutBasketLoading}
							disableModification={true}
							inline={true}
							invertTotalRowOrder={true}
							noHeader={true}
							noDeliveryMethodWarningText="Please choose a delivery option at checkout" />
					</Flex>
					<Flex
						className={scss.buttons}
						gap={1}>
						<Button
							label="Continue Shopping"
							onClick={this.constructor.handleClose}
							variant="outlined" />
						{((window.location.pathname !== Routes.checkout) && this.renderCheckoutButton())}
					</Flex>
				</Flex>
			</DialogInterstitial>
		);
	}


	/**
	 * Render the "checkout now" button.
	 *
	 * @return {ReactNode}
	 */
	renderCheckoutButton() {
		return (
			<Button
				label="Go to Checkout"
				onClick={this.constructor.handleCheckout}
				size="large" />
		);
	}


	/**
	 * Render the error banner.
	 *
	 * @return {ReactNode}
	 */
	renderErrors() {

		const addedCount = this.props.checkoutBasketAdditionData?.Claims?.length;
		const errorCount = this.props.checkoutBasketAdditionData?.Errors?.length;
		const claimCount = (addedCount + errorCount);

		return (
			<Banner
				gap={0.5}
				severity="error"
				title={this.errorsTitle}>
				{(((errorCount === claimCount) && (errorCount === 1)) ? <String noFlex={true} str={ItemClaimErrorCodes.getMessage(this.props.checkoutBasketAdditionData?.Errors?.[0]?.Code)} /> : this.renderErrorsDetails())}
				<String str="The content of your basket is shown below." />
			</Banner>
		);

	}


	/**
	 * Render the item error details.
	 *
	 * @return {ReactNode}
	 */
	renderErrorsDetails() {
		return (
			<ul className={scss.errorsUl}>
				{
					this.props.checkoutBasketAdditionData?.Errors?.map((error, key) => {

						const item = this.props.checkoutBasketAdditionData?.Basket?.find(i => (i?.Uuid === error?.Uuid));

						// (ESLint range bug with template syntax)
						// eslint-disable-next-line no-useless-concat, prefer-template
						const itemLabel = (BasketItem.getUiLabel(item) + "(" + BasketItem.getUiSubtext(item) + ")" + ": ");

						return (
							<li key={key}>
								<String
									bold={true}
									noFlex={true}
									str={itemLabel}
									variant="body1" />
								<String
									noFlex={true}
									str={ItemClaimErrorCodes.getMessage(error?.Code)}
									variant="body1" />
							</li>
						);

					})
				}
			</ul>
		);
	}


	/**
	 * Get the error banner title string.
	 *
	 * @return {String}
	 */
	get errorsTitle() {

		const addedCount = this.props.checkoutBasketAdditionData?.Claims?.length;
		const errorCount = this.props.checkoutBasketAdditionData?.Errors?.length;
		const claimCount = (addedCount + errorCount);

		if (errorCount === claimCount) {
			if (errorCount === 1) {
				return "We couldn't add that item to your basket";
			}
			else return "We couldn't add those items to your basket";
		}
		else return "Not all items could be added to your basket";

	}


	/**
	 * Navigate to the checkout.
	 *
	 * @return {void}
	 */
	static handleCheckout = () => {
		this.handleClose();
		Navigator.navigate(Routes.checkout);
	};


	/**
	 * Close the dialog.
	 *
	 * @return {void}
	 */
	static handleClose = () => {
		dCheckoutBasketAdditionData(null);
	};

}

export default connect(({seatReservationDialog}) => ({forceClosed: seatReservationDialog}))(withCheckoutBasket(AddedToBasketOverlay));
