export default {

	/**
	 * API override
	 * 
	 * @type {String|null}
	 */
	api: null,

	/**
	 * Arguments string
	 *
	 * @type {String|null}
	 */
	args: null,

	/**
	 * Authentication
	 *
	 * @type {Object}
	 */
	auth: {

		/**
		 * Account ID
		 * 
		 * @type {Integer|null}
		 */
		AccountId: null,

		/**
		 * Authentication token
		 * 
		 * @type {String|null}
		 */
		AuthToken: null,

		/**
		 * Expiration time
		 * 
		 * @type {Integer|null}
		 */
		Expiration: null,

		/**
		 * Account is foreign to this railway
		 * 
		 * @type {Boolean}
		 */
		Foreign: false,

		/**
		 * Session token
		 * 
		 * @type {String|null}
		 */
		Token: null

	},

	/**
	 * Checkout basket items
	 * 
	 * @type {Array}
	 */
	checkoutBasket: [],

	/**
	 * Checkout basket addition data (last addition's server response)
	 *
	 * @type {Object|null}
	 */
	checkoutBasketAdditionData: null,

	/**
	 * Checkout basket discounts
	 *
	 * @type {Array<Object>}
	 */
	checkoutBasketDiscounts: [],

	/**
	 * Checkout basket ID
	 *
	 * @type {Integer|null}
	 */
	checkoutBasketId: null,

	/**
	 * Checkout basket expiry time
	 *
	 * The transaction must be complete by this time.
	 * 
	 * @type {Integer|null}
	 */
	checkoutBasketExpiry: null,

	/**
	 * Checkout basket operations ongoing (e.g. adding/removing items)?
	 *
	 * @type {Boolean}
	 */
	checkoutBasketLoading: false,

	/**
	 * Order vouchers (gift vouchers) applied in the checkout
	 *
	 * @type {Array}
	 */
	checkoutOrderVouchers: [],

	/**
	 * Checkout has completed and we've redirected
	 *
	 * Used so the next view knows to display a success banner.
	 * 
	 * @type {Boolean}
	 */
	checkoutRedirect: false,

	/**
	 * Checkout redirect URI
	 *
	 * The user is directed to this URI once checkout completes.
	 *
	 * @type {String|null}
	 */
	checkoutRedirectUri: null,

	/**
	 * Features to enable
	 * 
	 * Array of enabled feature names.
	 * 
	 * @type {Array}
	 */
	features: [],

	/**
	 * Array of features offered by API
	 *
	 * Maps the feature name to preview mode status.
	 *
	 * Value of `true` = available, `false` = available in preview.
	 * 
	 * @type {Object}
	 */
	featuresAvailable: {},

	/**
	 * Feature names that have been forced-enabled
	 *
	 * @type {Array}
	 */
	featuresForced: [],

	/**
	 * Loading - this is for main UI components
	 * 
	 * @type {Boolean}
	 */
	loading: true,

	/**
	 * Organisation
	 * 
	 * @type {Object}
	 */
	org: {

		/**
		 * ID
		 *
		 * @type {Integer|null}
		 */
		Id: null,

		/**
		 * Name
		 *
		 * @type {String|null}
		 */
		Name: null,

		/**
		 * Name (short variant)
		 * 
		 * @type {String|null}
		 */
		NameShort: null,

		/**
		 * Logo absolute URI
		 * 
		 * @type {String|null}
		 */
		Logo: null,

		/**
		 * Marketing consents offered by organisation
		 * 
		 * @type {Array|null}
		 */
		MarketingConsents: null,

		/**
		 * Gift Aid enabled?
		 * 
		 * @type {Boolean}
		 */
		GiftAidEnabled: null,

		/**
		 * Saves date-of-birth?
		 *
		 * @type {Boolean}
		 */
		SavesDob: null,

		/**
		 * Org's website URL
		 *
		 * @type {String|null}
		 */
		WebsiteUrl: null

	},

	/**
	 * Payment methods
	 *
	 * @type {Array<Object>}
	 */
	paymentMethods: [],

	/**
	 * Route key
	 *
	 * This is used to force navigation re-renders.
	 *
	 * @type {Integer}
	 */
	rkey: 0,

	/**
	 * Seat reservation dialog state
	 *
	 * @type {?String} Item UUID to render for
	 */
	seatReservationDialog: null,

	/**
	 * Strings
	 * 
	 * @type {Object}
	 */
	strings: {

		/**
		 * Index text
		 * 
		 * @type {String|null}
		 */
		index: null

	},

	/**
	 * Theme
	 * 
	 * @type {Object}
	 */
	theme: {

		/**
		 * Accent colour
		 * 
		 * @type {String|null} Hex
		 */
		AccentColour: null,

		/**
		 * Accent colour (secondary)
		 * 
		 * @type {String|null} Hex
		 */
		AccentColourSecondary: null,

		/**
		 * Google Fonts name
		 *
		 * @type {String|null}
		 */
		FontFamily: null

	},

	/**
	 * Material UI base theme
	 * 
	 * @type {String}
	 */
	themeMaterial: "auto",

	/**
	 * Ticket system registration data
	 * 
	 * @type {Object}
	 */
	ticketsRegistration: {

		/**
		 * Available ticket categories
		 *
		 * @type {Array|null}
		 */
		Categories: null,

		/**
		 * Available ticket classes
		 *
		 * @type {Array|null}
		 */
		Classes: null,

		/**
		 * Available fare types
		 *
		 * @type {Array|null}
		 */
		FareTypes: null,

		/**
		 * Local Ticket System registration data
		 *
		 * @type {Object|null}
		 */
		Registration: null,

		/**
		 * Available timing points (stations)
		 * 
		 * @type {Array|null}
		 */
		TimingPoints: null,

		/**
		 * Available ticket types
		 *
		 * @type {Array|null}
		 */
		Types: null

	},

	/**
	 * Ticket system selection
	 * 
	 * @type {Object}
	 */
	ticketsSelection: {

		/**
		 * Travel date
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		Date: null,

		/**
		 * Fare type
		 *
		 * @type {Object|null} 
		 */
		FareType: null,

		/**
		 * Journey type
		 * 
		 * @type {Integer|null} ID
		 */
		JourneyType: null,

		/**
		 * Timing point A
		 *
		 * @type {Object|null}
		 */
		TimingPointA: null,

		/**
		 * Timing point B
		 *
		 * @type {Object|null}
		 */
		TimingPointB: null,

		/**
		 * Timing point C
		 * 
		 * @type {Object|null}
		 */
		TimingPointC: null,

		/**
		 * Number of each type of ticket to buy
		 *
		 * Ticket type ID => number of this type to buy.
		 *
		 * @type {Object}
		 */
		TypeCounts: {},

		/**
		 * Sessions - Selected ticket category
		 *
		 * (This gets set to the slug from the URL ASAP, so the global 
		 * filter bar shows the selected category immediately, then gets 
		 * resolved to category object by the category view once available.)
		 * 
		 * @type {Object|String|null} Category object or slug
		 */
		Category: null,

		/**
		 * Sessions - Selected ticket product
		 *
		 * (This should always be a product within the `Category` category!)
		 * 
		 * @type {String|null} Slug
		 */
		Product: null

	},

	/**
	 * Tickets view header image configuration
	 * 
	 * @type {Object}
	 */
	ticketsViewHeaderImg: {

		/**
		 * Alt text
		 *
		 * @type {String|null}
		 */
		alt: null,

		/**
		 * Source URL
		 *
		 * @type {String|null}
		 */
		src: null

	},

	/**
	 * Ticket system registration data
	 * 
	 * @type {Object}
	 */
	sharesRegistration: {

		/**
		 * Available ticket categories
		 *
		 * @type {Array|null}
		 */
		Companies: null,

		/**
		 * Local Ticket System registration data
		 *
		 * @type {Object|null}
		 */
		Registration: null

	}

};
