import api from "api.js";
import dAddToBasket from "Dispatchers/dAddToBasket.js";
import dCheckoutBasket from "Dispatchers/dCheckoutBasket.js";
import dCheckoutBasketAdditionData from "Dispatchers/dCheckoutBasketAdditionData.js";
import dCheckoutBasketDiscounts from "Dispatchers/dCheckoutBasketDiscounts.js";
import dCheckoutBasketIdentity from "Dispatchers/dCheckoutBasketIdentity.js";
import dCheckoutBasketLoading from "Dispatchers/dCheckoutBasketLoading.js";
import dCheckoutBasketReset from "Dispatchers/dCheckoutBasketReset.js";
import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";
import dUpdateBasketItem from "Dispatchers/dUpdateBasketItem.js";
import snack from "App/SnackbarHost.js";
import pluralize from "pluralize";
import Store from "App/Store.js";
import {CheckoutBasketItem as BasketItem, CheckoutService as CheckoutServiceHps, OrderSources, OrderableTypes, TicketSeatReservationModes} from "@hps/hops-sdk-js";
import dRemoveFromBasket from "Dispatchers/dRemoveFromBasket";

/**
 * Checkout service
 *
 * Abstracts the HOPS checkout API.
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CheckoutService {

	/**
	 * Add items to the basket.
	 *
	 * `dispatchAdditionData`:
	 *  - `true` = dispatch the basket addition response data to the state
	 *  - `null` = only dispatch basket addition data to state when there's errors
	 *  - `false` = don't dispatch any basket addition response data to state
	 *
	 * @param {Array<BasketItem>} items
	 * @param {Boolean|null} dispatchAdditionData optional (`true`)
	 * @return {void}
	 */
	static addBasketItems(items, dispatchAdditionData=true) {

		dCheckoutBasketLoading(true);

		return api.call({
			url: "/api/checkout/basket",
			method: "POST",
			data: {
				Id: this.basketId,
				Items: items.map(i => {
					return {
						Uuid: i.Uuid,
						DeliveryMethodItemUuid: i.DeliveryMethodItemUuid,
						RelatedItemUuid: i.RelatedItemUuid,
						Item: BasketItem.getBasketApiData(i),
						OrderableType: i.OrderableType,
						Price: i.Price,
						Quantity: i.Quantity
					};
				}),
				Source: OrderSources.Local
			}
		}).then(({data}) => {

			dCheckoutBasketIdentity(data?.Basket?.Id, data?.Basket?.Expiry);
			dAddToBasket(items.filter(i => data?.Claims?.includes(i.Uuid)));
			dCheckoutBasketDiscounts((data?.Basket?.Discounts || []));

			if (dispatchAdditionData || ((dispatchAdditionData === null) && data?.Errors?.length)) dCheckoutBasketAdditionData({...data, Basket: items});

			if ((data && (data.Claims?.length === 1)) && (items.length === 1)) {
				const item = items[0];
				if (TicketSeatReservationModes.allowsCustomerSelection(BasketItem.getSeatReservationMode(item))) {
					dSeatReservationDialog(item.Uuid);
				}
			}

			return data;

		}).finally(() => {
			dCheckoutBasketLoading(false);
		});

	}


	/**
	 * Add a Delivery Method item to a Basket and make it the selected Delivery Method
	 *  item for a specified list of existing basket items.
	 *
	 * @param {Array<BasketItem>} deliveryMethodItem Basket Item object defining the Delivery Method Item to add
	 * @param {Array<String>} assignmentTargets List of UUIDs of items already in the basket that this Delivery Method should be assigned to.
	 * @return {void}
	 */
	static addDeliveryMethod(deliveryMethodItem, AssignmentTargets) {

		dCheckoutBasketLoading(true);

		return api.call({
			url: `/api/checkout/basket/${this.basketId}/deliverymethods`,
			method: "POST",
			data: {
				DeliveryMethodItem: {
					...deliveryMethodItem,
					Item: BasketItem.getBasketApiData(deliveryMethodItem)
				},
				AssignmentTargets
			}
		}).then(({data}) => {

			/**
			 * When an error occurred, we dispatch "fake" basket
			 * addition data in the format returned by the "add to
			 * basket" endpoint so the "added to basket" overlay
			 * appears and the user can review the issue.
			 */
			if (data?.Error) {
				dCheckoutBasketAdditionData({
					Basket: [deliveryMethodItem],
					Claims: [],
					Errors: [
						{
							Uuid: deliveryMethodItem.Uuid,
							Code: data.Error
						}
					]
				});
			}
			else if (data?.ItemUuids?.includes(deliveryMethodItem.Uuid)) {

				// Add the delivery method to the basket

				dAddToBasket([deliveryMethodItem]);

				// Update all the assignment targets with the DeliveryMethodUuid
				AssignmentTargets.forEach(target => {

					// We actually replace it because items are supposed to be immutable
					const targetItem = this.basket.find(i => (i.Uuid === target));

					if (targetItem) {
						dUpdateBasketItem(target, {
							...targetItem,
							Item: {
								...targetItem.Item,
								DeliveryMethod: deliveryMethodItem
							},
							DeliveryMethodItemUuid: deliveryMethodItem.Uuid
						});
					}

				});

			}

			// Extend the basket expiry, since we have it in the response and may as well

			dCheckoutBasketIdentity(data?.Id, data?.Expiry);

			// Remove any orphaned delivery methods as a result of basket changes
			this.removeOrphanDeliveryMethods();

		}).finally(() => {
			dCheckoutBasketLoading(false);
		});
	}


	/**
	 * Add a Delivery Method item to a Basket and make it the selected Delivery Method
	 *  item for a specified list of existing basket items.
	 *
	 * @param {Array<BasketItem>} item Basket Item object defining the Delivery Method Item to add
	 * @param {Array<String>} assignmentTargets List of UUIDs of items already in the basket that this Delivery Method should be assigned to.
	 * @return {void}
	 */
	static setItemDeliveryMethod(item, deliveryMethodItem) {

		dCheckoutBasketLoading(true);

		return api.call({
			url: `/api/checkout/basket/${this.basketId}/items/${item.Uuid}/deliverymethod`,
			method: "PUT",
			data: {
				DeliveryMethodItem: deliveryMethodItem.Uuid
			}
		}).then(() => {

			// Update the basket item to reference the DeliveryMethodItemUuid
			dUpdateBasketItem(item.Uuid, {
				...item,
				Item: {
					...item.Item,
					DeliveryMethod: deliveryMethodItem
				},
				DeliveryMethodItemUuid: deliveryMethodItem.Uuid
			});

			// Endpoint doesn't give us a basket back, so we can't update basket identity/expiry here

			// Remove any orphaned delivery methods as a result of basket changes
			this.removeOrphanDeliveryMethods();

		}).finally(() => {
			dCheckoutBasketLoading(false);
		});
	}


	/**
	 * Remove any orphaned delivery methods (that aren't referenced through a DeliveryMethodUuid to any other items in the basket)
	 */
	static removeOrphanDeliveryMethods() {

		const orphanBasketDeliveryMethods = this.basket
			.filter(
				i => (
					// Delivery Method items
					i.OrderableType === OrderableTypes.DeliveryMethod &&

					// That aren't referenced by any other basket items
					!this.basket.some(item => item.DeliveryMethodItemUuid === i.Uuid))
			)
			.map(i => i.Uuid);

		dRemoveFromBasket(orphanBasketDeliveryMethods);

	}


	/**
	 * Remove items from the basket.
	 *
	 * @param {Array<BasketItem>} items
	 * @param {Boolean} updateLocalState optional Update local state (`true`)
	 * @return {void}
	 */
	static removeBasketItems(items, updateLocalState=true) {

		dCheckoutBasketLoading(true);

		/**
		 * We need to cache the discounts we currently have applied 
		 * so we can detect removals and show a warning message to 
		 * the user later on
		 */
		const currentDiscounts = [...(Store.getState().checkoutBasketDiscounts || [])];

		/**
		 * Make the API call
		 */
		const promise = CheckoutServiceHps.removeBasketItems(
			items,
			this.basketId
		).finally(() => {
			dCheckoutBasketLoading(false);
		});

		/**
		 * We need to update the state now
		 */
		if (updateLocalState) {
			promise.then(data => {

				dCheckoutBasket(Store.getState().checkoutBasket.filter(i => data.ItemUuids?.includes?.(i.Uuid)));
				dCheckoutBasketIdentity(data?.Id, data?.Expiry);

				const updatedDiscounts = (data?.Discounts || []);
				dCheckoutBasketDiscounts(updatedDiscounts);

				this.warnUserDiscountCodesRemoved(
					updatedDiscounts,
					currentDiscounts,
					"Item removed from the basket"
				);

				// Remove any orphaned delivery methods as a result of basket changes
				this.removeOrphanDeliveryMethods();

			});
		}

		return promise;

	}


	/**
	 * Update the quantity of an item in the basket.
	 *
	 * When `forceDispatchBasketAdditionData` is `true`, always 
	 * dispatches basket addition data after completion, forcing 
	 * the basket contents overlay to appear so the user gets 
	 * confirmation of the quantity change. Otherwise addition 
	 * data is only dispatched when a claim error occurs (including 
	 * actual data describing the item claim error).
	 * 
	 * @param {BasketItem} Item Updated basket item definition
	 * @param {Boolean} optional forceDispatchBasketAdditionData (`false`)
	 * @return {Promise}
	 */
	static updateBasketItemQuantity(Item, forceDispatchBasketAdditionData=false) {

		if (Item.Quantity === 0) {
			return this.removeBasketItems(Item);
		}

		dCheckoutBasketLoading(true);

		return api.call({
			url: `/api/checkout/basket/${this.basketId}/items/${Item.Uuid}/quantity`,
			method: "PUT",
			data: {Quantity: Item.Quantity}
		}).then(({data}) => {

			/**
			 * When an error occurred, we dispatch "fake" basket
			 * addition data in the format returned by the "add to
			 * basket" endpoint so the "added to basket" overlay
			 * appears and the user can review the issue.
			 *
			 * (This will only occur when increasing quantity.)
			 */
			if (data?.Error) {
				dCheckoutBasketAdditionData({
					Basket: [Item],
					Claims: [],
					Errors: [
						{
							Uuid: Item.Uuid,
							Code: data.Error
						}
					]
				});
			}

			else {

				dUpdateBasketItem(Item.Uuid, Item);
				dCheckoutBasketDiscounts((data?.Discounts || []));

				if (forceDispatchBasketAdditionData) {
					dCheckoutBasketAdditionData({});
				}

				// Remove any orphaned delivery methods as a result of basket changes
				this.removeOrphanDeliveryMethods();

			}

		}).finally(() => {
			dCheckoutBasketLoading(false);
		});

	}


	/**
	 * Extend the lifetime of our basket.
	 * 
	 * @return {Promise}
	 */
	static extendBasketLifetime() {

		const id = this.basketId;

		return api.call({
			url: `/api/checkout/basket/${id}/ping`,
			method: "POST"
		}).then(({data}) => {

			/**
			 * Make sure basket did not expire during the API call
			 */
			if (id === this.basketId) {
				dCheckoutBasketIdentity(data?.Id, data?.Expiry);
			}

		});

	}


	/**
	 * Remove all items from the basket.
	 *
	 * Network errors don't matter here; we need the user to see 
	 * an empty basket, and if the call fails, we just have the basket 
	 * on the server still, and it will be cleared when it expires.
	 *
	 * @return {void}
	 */
	static emptyBasket() {
		const items = Store.getState().checkoutBasket;
		this.removeBasketItems(items, false).catch(() => undefined);
		dCheckoutBasketReset();
	}


	/**
	 * Checkout a transaction.
	 *
	 * @param {Object} data Checkout API body
	 * @return {Promise} Resolves with checkout API data
	 */
	static checkout(data) {
		return api.call({
			url: "/api/checkout",
			method: "POST",
			data
		}).then(({data}) => data);
	}


	/**
	 * Warn the user that discount codes have been removed from the basket.
	 *
	 * @param {Array<Object>} discounts Currently applied discount claim objects
	 * @param {Array<Object>} prevDiscounts Previously applied claim objects
	 * @param {String} prefix Message to prepend to the warning
	 * @return {void}
	 */
	static warnUserDiscountCodesRemoved(discounts, prevDiscounts, prefix="") {

		const removed = [];

		/**
		 * Map our claim objects to the discount codes they refer to
		 */
		const codes = discounts.map(claim => claim?.Discount?.Code);
		const prevCodes = prevDiscounts.map(claim => claim?.Discount?.Code);

		/**
		 * Discover which codes have gone
		 */
		for (const code of prevCodes) {
			if (!codes.includes(code) && !removed.includes(code)) {
				removed.push(code);
			}
		}

		/**
		 * Have we removed any codes?
		 */
		if (removed?.length > 0) {

			const codesTerm = pluralize("code", removed.length);

			snack(
				[
					(prefix ? `${prefix} - ` : ``),
					`${(!prefix ? "Discount" : "discount")} ${codesTerm} no longer being used:`,
					`${removed.join(", ")}.`,
					`\nIf you add another qualifying item to your basket, please re-enter the ${codesTerm}.`
				].join(" "),
				"default",
				false,
				{
					style: {
						whiteSpace: "pre-line"
					}
				}
			);

		}

	}


	/**
	 * Get the checkout basket ID.
	 *
	 * @return {String|null}
	 */
	static get basketId() {
		return (Store.getState().checkoutBasketId || null);
	}


	/**
	 * Get the checkout basket.
	 *
	 * @return {Array<Object>}
	 */
	static get basket() {
		return (Store.getState().checkoutBasket);
	}

}

export default CheckoutService;
