import React from "react";
import AppService from "Services/AppService.js";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import Container from "Components/Container.js";
import Image from "Components/Image.js";
import Navigator from "App/Navigator.js";
import Routes from "Resources/Routes.json";
import withMobile from "Hoc/withMobile.js";
import withOrg from "Hoc/withOrg.js";
import scss from "./UiBar.module.scss";
import throttle from "lodash.throttle";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import {AppBar, Badge, CardActionArea, Toolbar} from "@material-ui/core";
import {CheckoutBasketItem as BasketItem} from "@hps/hops-sdk-js";
import * as icons from "Resources/Icons.js";

/**
 * UI bar
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBar extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Collapsed?
		 * 
		 * @type {Boolean}
		 */
		collapsed: this.constructor.collapsedScrollPosition

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}


	/**
	 * Scroll handler.
	 * 
	 * @return {void}
	 */
	handleScroll = throttle(() => {
		this.setState({collapsed: this.constructor.collapsedScrollPosition});
	}, 150);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AppBar className={scss.AppBar} position="fixed" style={this.styles}>
				<Toolbar className={scss.Toolbar} disableGutters={true} style={this.styles}>
					<Container
						alignContent="center"
						alignItems="center"
						className={scss.Container}
						columnar={true}
						gridTemplateRows="70%"
						height="100%"
						justifyContent="space-between"
						width="100%">
						<Flex
							className={(!this.props.isMobile ? scss.IconButtonContainer : scss.IconButtonContainer_small)}
							columnar={true}
							gap={(!this.props.isMobile ? 2 : 1)}>
							<Button
								classes={this.constructor.iconButtonClasses}
								label="Home"
								icon={icons.HomeIcon}
								size={(this.props.isMobile ? "small" : undefined)}
								uri={Routes.index} />
						</Flex>
						<CardActionArea
							classes={this.constructor.logoButtonClasses}
							onClick={this.constructor.handleLogoClick}>
							<Image
								alt={this.props.org.Name}
								imgStyles={this.constructor.imageStylesInner}
								noError={true}
								noLoader={true}
								src={AppService.getRegistrationLogo()}
								style={this.constructor.imageStyles} />
						</CardActionArea>
						<Flex
							className={(!this.props.isMobile ? scss.IconButtonContainer : scss.IconButtonContainer_small)}
							columnar={true}
							gap={(!this.props.isMobile ? 2 : 1)}
							justifyContent="flex-end">
							<Badge
								badgeContent={this.props.checkoutBasket?.filter(i => !BasketItem.isInternal(i))?.reduce((n, {Quantity}) => n + Quantity, 0)}
								className={scss.CheckoutBadge}
								color="error"
								overlap="circle">
								<Button
									classes={this.constructor.iconButtonClasses}
									label="Basket"
									icon={icons.BasketIcon}
									size={(this.props.isMobile ? "small" : undefined)}
									uri={Routes.checkout} />
							</Badge>
							<Button
								classes={this.constructor.iconButtonClasses}
								label="Members' Area"
								icon={icons.AccountIcon}
								size={(this.props.isMobile ? "small" : undefined)}
								uri={Routes.account} />
						</Flex>
					</Container>
				</Toolbar>
			</AppBar>
		);
	}


	/**
	 * Get our height.
	 * 
	 * @return {String} CSS
	 */
	get height() {
		if (this.props.isMobile || this.state.collapsed) return "9rem";
		else return "12rem";
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			height: this.height,
			minHeight: this.height,
			transition: "all 0.2s ease-out",
			transitionProperty: "height, min-height"
		};
	}


	/**
	 * Logo clicked.
	 * 
	 * @return {void}
	 */
	static handleLogoClick() {
		Navigator.navigate(Routes.index);
	}


	/**
	 * Get whether we're at a scroll position where we should collapse.
	 * 
	 * @return {Boolean}
	 */
	static get collapsedScrollPosition() {
		return (window.scrollY > 120);
	}


	/**
	 * Styles (image).
	 *
	 * @type {Object}
	 */
	static imageStyles = {
		height: "100%",
		justifyContent: "center"
	};

	/**
	 * Styles (image, inner).
	 * 
	 * @type {Object}
	 */
	static imageStylesInner = {
		height: "100%",
		objectFit: "contain"
	};

	/**
	 * Icon button classes.
	 * 
	 * @type {Object}
	 */
	static iconButtonClasses = {root: scss.IconButton};

	/**
	 * Classes for the logo button.
	 * 
	 * @type {Object}
	 */
	static logoButtonClasses = {root: scss.LogoButton};

}

export default withCheckoutBasket(withMobile(withOrg(UiBar)));
