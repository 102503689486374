import React from "react";
import ThemeResource from "Resources/Theme.json";
import {connect} from "react-redux";
import {foregroundColour} from "Helpers/Colour.js";

/**
 * `withTheme` HOC wrapper
 *
 * Makes the active state theme object available on a component as `theme`.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withTheme = Component => {

	/**
	 * `ComponentWithTheme` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwt = class ComponentWithTheme extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					contrastTextColour={foregroundColour(this.props.theme.AccentColour)}
					contrastTextColourSecondary={foregroundColour(this.props.theme.AccentColourSecondary)}
					isDarkTheme={this.isDarkTheme}
					mergeTheme={this.mergeTheme}
					resolvedTheme={this.resolvedTheme}
					theme={this.props.theme}
					themeMaterial={this.props.themeMaterial} />
			);
		}


		/**
		 * Merge a theme object into the main theme object.
		 *
		 * Enables custom sub-theme creation.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		mergeTheme = data => {

			const theme = this.resolvedTheme;

			const assign = (target, obj) => {
				for (const key in obj) {
					if ((typeof target[key] === "object") && (typeof obj[key] === "object")) {
						assign(target[key], obj[key]);
					}
					else target[key] = obj[key];
				}
			};

			assign(theme, data);

			return theme;

		};


		/**
		 * Get whether we're using dark theme.
		 * 
		 * @return {Boolean}
		 */
		get isDarkTheme() {
			return (this.props.themeMaterial === "dark");
		}


		/**
		 * Get the resolved final theme object.
		 * 
		 * @return {Object}
		 */
		get resolvedTheme() {

			const theme = ThemeResource.App;

			if (this.props.theme.AccentColour) {
				theme.palette.primary.main = this.props.theme.AccentColour;
			}
			if (this.props.theme.AccentColourSecondary) {
				theme.palette.secondary.main = this.props.theme.AccentColourSecondary;
			}
			if (this.props.theme.FontFamily) {
				theme.typography.fontFamily = `${this.props.theme.FontFamily}, sans-serif, serif`;
			}

			if (this.props.themeMaterial === "auto") {
				theme.palette.type = "light";
			}
			else theme.palette.type = this.props.themeMaterial;

			return theme;

		}

	};

	return connect(({theme, themeMaterial}) => ({theme, themeMaterial}))(cwt);

};

export default withTheme;
