import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Badge from "@material-ui/core/Badge";
import Button from "Components/Button.js";
import CheckoutService from "Services/CheckoutService.js";
import Container from "Components/Container.js";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import SeatingIcon from "@material-ui/icons/EventSeatOutlined";
import Flex from "Components/Flex.js";
import PriceHelper from "Helpers/Price.js";
import String from "Components/String.js";
import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";
import pluralize from "pluralize";
import withSnack from "Hoc/withSnack.js";
import scss from "./BasketCardItem.module.scss";
import {CheckoutBasketItem as BasketItem, OrderableTypes, TicketSeatReservationModes} from "@hps/hops-sdk-js";
import Hidden from "Components/Hidden";
import withCheckoutBasket from "Hoc/withCheckoutBasket";
import {DeliveryMethods} from "@hps/hops-sdk-js";

/**
 * Basket card item
 *
 * Renders an individual item in `BasketCard`.
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class BasketCardItem extends AsyncStatefulComponent {

	/**
	 * Remove the item from the basket.
	 * 
	 * @return {void}
	 */
	handleRemove = async () => {

		if (this.props.onRemoving) {
			this.props.onRemoving(true);
		}

		try {
			await CheckoutService.removeBasketItems([this.props.item]);
		}
		catch (e) {
			this.props.snack(e);
		}

		if (this.props.onRemoving) {
			this.props.onRemoving(false);
		}

	};


	/**
	 * Seat reservation button handler.
	 *
	 * @return {void}
	 */
	handleSeatReservation = () => {
		dSeatReservationDialog(this.props.item.Uuid);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const {Price, Quantity} = this.props.item;
		let label = BasketItem.getUiLabel(this.props.item);

		if (Quantity > 1) {
			label += ` (@ ${PriceHelper.getUiString(Price)})`;
		}

		const supportsSeatReservation = TicketSeatReservationModes.allowsCustomerSelection(BasketItem.getSeatReservationMode(this.props.item));

		return (
			<Container
				columns="max-content 1fr">
				<Flex
					alignItems="center"
					gap={0}>
					<Hidden hidden={this.props.item.OrderableType === OrderableTypes.DeliveryMethod}>
						<String
							color="textSecondary"
							noFlex={true}
							str={`${Quantity}x`} />
						{(!this.props.disableModification && this.renderDelete())}
					</Hidden>
				</Flex>
				<Flex
					gap={0.375}>
					<Flex
						columnar={true}
						justifyContent="space-between">
						<String
							noFlex={true}
							str={label} />
						<String
							className={scss.price}
							noFlex={true}
							str={PriceHelper.getUiString((Price * Quantity))} />
					</Flex>
					{this.renderDiscounts()}
					{this.renderDetails()}
					{this.renderDeliveryMethod()}
					{(!this.props.disableModification && supportsSeatReservation && this.renderSeatReservation())}
				</Flex>
			</Container>
		);

	}


	/**
	 * Render the delete button.
	 *
	 * @return {ReactNode}
	 */
	renderDelete() {
		return (
			<Button
				defaultStyling={true}
				disabled={this.props.disabled}
				icon={DeleteIcon}
				onClick={this.handleRemove}
				size="small" />
		);
	}


	/**
	 * Render the details section.
	 * 
	 * @return {ReactNode}
	 */
	renderDetails() {

		const details = BasketItem.getUiDetails(this.props.item);


		if (details?.length) {
			return (
				<details>
					<summary className={scss.summary} title="View Details">
						{this.renderDetailsHeader()}
					</summary>
					<Flex gap={0.25} pl={0.75} mt={0.25}>
						{
							details.map((detail, key) => (
								<div key={key}>
									<String
										display="inline-block"
										noFlex={true}
										str={`${detail.label}${(detail.value ? ": " : "")}`}
										variant="body2"
										whiteSpace="pre-wrap" />
									<String
										color="textSecondary"
										display="inline-block"
										noFlex={true}
										str={detail.value}
										variant="body2" />
								</div>
							))
						}
					</Flex>
				</details>
			);
		}
		else return this.renderDetailsHeader();

	}


	/**
	 * Render the details section's header.
	 *
	 * @return {ReactNode}
	 */
	renderDetailsHeader() {
		return (
			<String
				color="textSecondary"
				display="inline-block"
				noFlex={true}
				str={BasketItem.getUiSubtext(this.props.item)}
				variant="body2" />
		);
	}


	/**
	 * Render the delivery method information.
	 *
	 * @return {ReactNode}
	 */
	renderDeliveryMethod() {
		if (this.props.item.DeliveryMethodItemUuid) {
			const deliveryMethodItem = this.props.checkoutBasket.find(i => i.Uuid === this.props.item.DeliveryMethodItemUuid);

			return (
				<String
					color="textSecondary"
					display="inline-block"
					noFlex={true}
					str={deliveryMethodItem?.Item?.Label}
					variant="body2" />
			);
		}
		else if (DeliveryMethods.OrderableTypesRequiringDelivery.includes(this.props.item.OrderableType)) {
			return (
				<String
					color="error"
					display="inline-block"
					noFlex={true}
					str={this.props.noDeliveryMethodWarningText || "No Delivery Method Selected"}
					variant="body2" />
			);
		}
		else {
			return <>{(this.props.item.OrderableType === OrderableTypes.DeliveryMethod)}</>;
		}
	}


	/**
	 * Render the discounts section.
	 * 
	 * @return {ReactNode}
	 */
	renderDiscounts() {

		const itemQty = this.props.item.Quantity;

		return this.props.discounts.map((discount, key) => {

			const {Code, Amount} = discount.Discount;
			const qty = Math.min(discount.DiscountQty, itemQty);
			const totalDiscount = Math.max((Amount * qty), 0);

			let label = `Discount: ${Code}`;

			if (qty !== itemQty) {
				label += ` (applied to ${qty} ${pluralize("item", qty)})`;
			}

			return (
				<Flex
					columnar={true}
					justifyContent="space-between"
					key={key}>
					<String
						color="error"
						noFlex={true}
						str={label}
						variant="body2" />
					<String
						className={scss.price}
						color="error"
						noFlex={true}
						str={`-${PriceHelper.getUiString(totalDiscount)}`}
						variant="body2" />
				</Flex>
			);

		});

	}


	/**
	 * Render the seat reservations button.
	 * 
	 * @return {ReactNode}
	 */
	renderSeatReservation() {
		return (
			<div>
				<Badge
					badgeContent={(this.props.pendingSeatSelection ? 1 : 0)}
					className={scss.seatReservationsBadge}
					color="error"
					variant="dot">
					<Button
						className={scss.seatReservationsBtn}
						color="secondary"
						label="Seat Reservations"
						onClick={this.handleSeatReservation}
						size="small"
						startIcon={<SeatingIcon />}
						variant="outlined" />
				</Badge>
			</div>
		);
	}

}

export default withCheckoutBasket(withSnack(BasketCardItem));
