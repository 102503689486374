import React from "react";
import TextField from "Components/TextField.js";

export default props => (
	<TextField
		controlled={props.controlled}
		disabled={props.disabled}
		inputRef={props.inputRef}
		label={(props.label || "Email address")}
		name={props.name}
		onChange={props.onChange}
		onEnter={props.onEnter}
		placeholder={props.placeholder}
		required={props.required}
		size={props.size}
		type="email"
		value={props.value}
		varchar={true}
		variant={(props.variant || "outlined")} />
);
